import React from 'react';

const HomeFaq = () => {
   return (
      <>
         <section id='faq' className="faq__area pb-200 pt-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-5">
                     <div className="faq__content">
                        <div className="section-title mb-45">
                           <h2>У вас остались вопросы?</h2>
                           <p>Мы собрали небольшую wiki из разных источников, которая поможет вам найти ответ</p>
                        </div>
                        <a href="https://adtech.beeline.ru/xwiki/bin/view/%D0%9E%D0%A0%D0%94/" rel="noreferrer" target="_blank" className="z-btn z-btn-border">Открыть wiki</a>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-7 offset-xl-2 offset-lg-1">
                     <div className="faq__accordion p-relative">

                        <div className="accordion" id="accordionExample" >
                           <div className="card accordion-item">
                              <div className="card-header accordion-header" id="acc_1" >
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                       Кто должен отчитываться в ОРД?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Рекламодатели, рекламораспространители и агенты, которые получили от них документально подтвержденные права на регистрацию креативов и подачу отчетности</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_2">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                       С чего мне начать?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Зарегистрироваться в кабинете ОРД, перед рекламной кампанией загрузить необходимые креативы и договоры в интерфейс системы, получить токен и использовать его в настройках рекламной кампании, добавив дополнительным параметром к URL</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_3">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                       Сколько стоит сервис ОРД?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>С 2025 года стоимость сервиса составит:<br />
                                       - 5000 ₽ без НДС - минимальный ежемесячный платёж<br />
                                       - 1% при стандартных условиях от общей суммы зарегистрированной рекламы в рамках отчётного месяца
                                    </p>
                                 <div/>
                              </div>
                           </div>
                        </div>
                        <div className="card">
                           <div className="card-header" id="acc_4">
                              <h5 className="mb-0">
                                 <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                    Где я могу взять договор?
                                 </button>
                              </h5>
                           </div>

                           <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                              <div className="card-body accordion-body">
                                 <p>В подвале нашего сайта вы всегда найдете актуальную версию договора или оферты</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section >
      </>
   );
};

export default HomeFaq;
